import { ChangeEvent, useState } from "react"
import styled from "styled-components"

import { passwordSignin } from "src/context/auth/signIn"
import { useAppData } from "src/context/useAppData"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

export function LoginForm({ prefillEmail }: { prefillEmail?: string }) {
  const { authState, loading } = useAppData()

  const [email, setEmail] = useState(prefillEmail ?? "")
  const [password, setPassword] = useState("")
  const [loggingIn, setLoggingIn] = useState(false)
  const [error, setError] = useState(false)

  const { t } = useTranslate()

  const handleSubmit = async (e: ChangeEvent<object>) => {
    e.preventDefault()

    setLoggingIn(true)
    passwordSignin({
      username: email,
      password,
      onSignedIn: ({ authorization }) =>
        authState.setAuthorization(authorization),
      onSettled: () => setLoggingIn(false),
      onError: () => setError(true),
    })
  }

  return (
    <FormBox onSubmit={handleSubmit}>
      <FieldsWrapper>
        <MTextField
          id="email"
          label={t(langKeys.email)}
          type="email"
          autoFocus
          required
          value={email}
          onChange={(value) => setEmail(value)}
          onBlur={(e) => setEmail(e.target.value.toLocaleLowerCase().trim())}
          disabled={!!prefillEmail}
        />

        <MTextField
          id="password"
          label={t(langKeys.password)}
          type="password"
          required
          value={password}
          onChange={(value) => setPassword(value)}
        />
      </FieldsWrapper>

      {error && (
        <StyledAlert type="error" fullWidth>
          {t(langKeys.sign_in_failed)}
        </StyledAlert>
      )}

      <MButton fullWidth type="submit" loading={loggingIn || loading}>
        {t(langKeys.sign_in)}
      </MButton>
    </FormBox>
  )
}

const FormBox = styled.form`
  font-size: 0.875rem;
`

const StyledAlert = styled(MBanner)`
  margin-bottom: ${spacing.S};
`

const FieldsWrapper = styled.div`
  display: grid;
  gap: ${spacing.L};
  margin-bottom: ${spacing.L};
`
